require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/25442a13bf5994886e959d86de55f5e3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_25442a13bf5994886e959d86de55f5e3'] = function fn_25442a13bf5994886e959d86de55f5e3(appData) {
var language;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  language = (getObjectProperty(appData, 'language'));
  if (language == null) {
    language = ((function(){ try { return JSON.parse(localStorage.getItem('language')) } catch(e){ return null }})());
  }

return language
}

})
define('./functions/5188216b7b39207784cdfbd7e8aca7d9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5188216b7b39207784cdfbd7e8aca7d9'] = function fn_5188216b7b39207784cdfbd7e8aca7d9(user, appData) {
  appData['user'] = user;
  localStorage.setItem('user', JSON.stringify(user));

}

})
define('./functions/9381ad6d0dd53b946a32f892b32fafee/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9381ad6d0dd53b946a32f892b32fafee'] = function fn_9381ad6d0dd53b946a32f892b32fafee(language, appData) {
  appData['language'] = language;
  localStorage.setItem('language', JSON.stringify(language));

}

})
define('./functions/955d5aaae1275eb30fc511115678a336/code.js', () => { 

BackendlessUI.Functions.Custom['fn_955d5aaae1275eb30fc511115678a336'] = async function fn_955d5aaae1275eb30fc511115678a336(user, locationCampaign) {
var entries, userLocationCampaign, locationCampaign;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  entries = [];
  var userLocationCampaign_list = (await Backendless.Data.of('UserLocationCampaign').find(Backendless.DataQueryBuilder.create().setWhereClause((['user.email=\'',(getObjectProperty(user, 'email')),'\' and locationCampaign.campaign.objectId=\'',(getObjectProperty(locationCampaign, 'campaign.objectId')),'\''].join(''))).setRelated(['locationCampaign']).setSortBy('created desc').setPageSize(100)));
  for (var userLocationCampaign_index in userLocationCampaign_list) {
    userLocationCampaign = userLocationCampaign_list[userLocationCampaign_index];
    locationCampaign = (await Backendless.Data.of('LocationCampaign').findById((getObjectProperty(userLocationCampaign, 'locationCampaign.objectId')), Backendless.DataQueryBuilder.create().setRelated(['location'])));
    addItemToList(entries, ({ 'locationName': (getObjectProperty(locationCampaign, 'location.name')),'entryCreated': ((new Date((getObjectProperty(userLocationCampaign, 'created')))).toLocaleString()) }));
  }

return entries
}

})
define('./functions/96d4c782a95aa8d68dd3ca047a99b17d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_96d4c782a95aa8d68dd3ca047a99b17d'] = function fn_96d4c782a95aa8d68dd3ca047a99b17d(appData) {
var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  user = (getObjectProperty(appData, 'user'));
  if (user == null) {
    user = ((function(){ try { return JSON.parse(localStorage.getItem('user')) } catch(e){ return null }})());
  }

return user
}

})
define('./functions/fdf7db16ad76081e4e2c4a0c05d61a83/code.js', () => { 

BackendlessUI.Functions.Custom['fn_fdf7db16ad76081e4e2c4a0c05d61a83'] = function fn_fdf7db16ad76081e4e2c4a0c05d61a83(language, user) {
var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (language == 'fr') {
    content2 = 'Merci d\'avoir participé à notre tirage ! Votre participation au concours pour ce lieu a été enregistrée pour l\'adresse courriel ';
  } else {
    content2 = 'Thank you for participating in our draw! Your contest entry for this location has been recorded for email address ';
  }
  content2 = String(content2) + String(String(getObjectProperty(user, 'email')) + String('.'));

return content2
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/Entry/components/91f26a18d04f464499341eb5adbaba4a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isContestBlockVisible'] = (!(getObjectProperty(___arguments.context.pageData, 'isContestBlockVisible')));

  },
  /* handler:onClick */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if (getObjectProperty(___arguments.context.pageData, 'isContestBlockVisible')) {
    if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
      content2 = 'Cacher les détails du concours';
    } else {
      content2 = 'Hide Contest Details';
    }
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
      content2 = 'Afficher les détails du concours';
    } else {
      content2 = 'Show Contest Details';
    }
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pageErrorMessage')) == null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Entry/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    var userLocationCampaign, language;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  language = (await BackendlessUI.Functions.Custom['fn_25442a13bf5994886e959d86de55f5e3'](___arguments.context.appData));
  if (language != null) {
    ___arguments.context.pageData['language'] = language;
  } else {
    await BackendlessUI.Functions.Custom['fn_9381ad6d0dd53b946a32f892b32fafee']('fr', ___arguments.context.appData);
    ___arguments.context.pageData['language'] = 'fr';
  }
  ___arguments.context.pageData['pageErrorMessage'] = null;
  ___arguments.context.pageData['formErrorMessage'] = null;
  ___arguments.context.pageData['isContestBlockVisible'] = false;
  ___arguments.context.pageData['isEntryConfirmationTextDisplayed'] = false;
  ___arguments.context.pageData['isEmailValidationTextDisplayed'] = false;
  ___arguments.context.pageData['isDrawEntriesVisible'] = false;
  ___arguments.context.pageData['user'] = (await BackendlessUI.Functions.Custom['fn_96d4c782a95aa8d68dd3ca047a99b17d'](___arguments.context.appData));
  try {
    ___arguments.context.pageData['locationCampaign'] = (await Backendless.Data.of('LocationCampaign').findById((getObjectProperty(___arguments.context.pageData, 'code')), Backendless.DataQueryBuilder.create().setRelated(['location', 'campaign'])));

  } catch (error) {
    if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
      ___arguments.context.pageData['pageErrorMessage'] = 'Une erreur est survenue.';
    } else {
      ___arguments.context.pageData['pageErrorMessage'] = 'An error has occurred.';
    }

  }
  if ((getObjectProperty(___arguments.context.pageData, 'locationCampaign')) == null) {
    if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
      ___arguments.context.pageData['pageErrorMessage'] = 'Une erreur est survenue.';
    } else {
      ___arguments.context.pageData['pageErrorMessage'] = 'An error has occurred.';
    }
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'locationCampaign')), 'campaign.isActive')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
      ___arguments.context.pageData['pageErrorMessage'] = 'Une erreur est survenue.';
    } else {
      ___arguments.context.pageData['pageErrorMessage'] = 'An error has occurred.';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'user')) != null) {
    userLocationCampaign = (await Backendless.Data.of('UserLocationCampaign').find(Backendless.DataQueryBuilder.create().setWhereClause((['user.email=\'',(getObjectProperty(___arguments.context.pageData, 'user.email')),'\' and locationCampaign.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'locationCampaign.objectId')),'\''].join(''))).setPageSize(10)))[0];
    if (userLocationCampaign != null) {
      if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
        ___arguments.context.pageData['pageErrorMessage'] = 'Vous avez déjà créé une entrée pour ce lieu.';
      } else {
        ___arguments.context.pageData['pageErrorMessage'] = 'You have already created an entry for this location.';
      }
    } else {
      userLocationCampaign = (await Backendless.Data.of('UserLocationCampaign').save( ({ 'description': [(getObjectProperty(___arguments.context.pageData, 'user.email')),' - ',(getObjectProperty(___arguments.context.pageData, 'locationCampaign.location.name')),' - ',(getObjectProperty(___arguments.context.pageData, 'locationCampaign.campaign.name'))].join('') }) ));
      await Backendless.Data.of('UserLocationCampaign').setRelation(userLocationCampaign, 'user', [(getObjectProperty(___arguments.context.pageData, 'user.objectId'))]);
      await Backendless.Data.of('UserLocationCampaign').setRelation(userLocationCampaign, 'locationCampaign', [(getObjectProperty(___arguments.context.pageData, 'locationCampaign.objectId'))]);
      ___arguments.context.pageData['isEntryConfirmationTextDisplayed'] = true;
      ___arguments.context.pageData['entryConfirmationTextFr'] = (await BackendlessUI.Functions.Custom['fn_fdf7db16ad76081e4e2c4a0c05d61a83']('fr', (getObjectProperty(___arguments.context.pageData, 'user'))));
      ___arguments.context.pageData['entryConfirmationTextEn'] = (await BackendlessUI.Functions.Custom['fn_fdf7db16ad76081e4e2c4a0c05d61a83']('en', (getObjectProperty(___arguments.context.pageData, 'user'))));
      ___arguments.context.pageData['drawEntries'] = (await BackendlessUI.Functions.Custom['fn_955d5aaae1275eb30fc511115678a336']((getObjectProperty(___arguments.context.pageData, 'user')), (getObjectProperty(___arguments.context.pageData, 'locationCampaign'))));
      ___arguments.context.pageData['isDrawEntriesVisible'] = true;
    }
  }

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/Entry/components/ee74c15f24586c1454efa569e1c640ef/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'user')) != null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Entry/components/134c6b2880de5ed24256e894b359ae29/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
    content2 = ['Bienvenue chez ',(getObjectProperty(___arguments.context.pageData, 'locationCampaign.location.name')),' !'].join('');
  } else {
    content2 = ['Welcome to ',(getObjectProperty(___arguments.context.pageData, 'locationCampaign.location.name')),'!'].join('');
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Entry/components/c42493be62286c3828e7c515dcb2091f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'user')) == null && (getObjectProperty(___arguments.context.pageData, 'pageErrorMessage')) == null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Entry/components/88a7250dbed28cb5ee125e11b6172b24/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pageErrorMessage')) == null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Entry/components/edb8a024bacd908570b7b352c9829e8b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pageErrorMessage')) == null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Entry/components/c17f943a83472c88b555691b5e9c40a9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pageErrorMessage')) == null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Entry/components/e28e101a0bcd115d1bda6b5f814362da/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pageErrorMessage')) == null && (getObjectProperty(___arguments.context.pageData, 'isDrawEntriesVisible')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
    content2 = 'Vos entrées au tirage au sort';
  } else {
    content2 = 'Your Draw Entries';
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Entry/components/8b1efc0a4dd9e937f70606e1a5cbf354/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pageErrorMessage')) == null && (getObjectProperty(___arguments.context.pageData, 'isDrawEntriesVisible')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Entry/components/26b45e143e8bc8d80e933b95b1dbb6a2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var language;


  language = (await BackendlessUI.Functions.Custom['fn_25442a13bf5994886e959d86de55f5e3'](___arguments.context.appData));
  if (language == 'fr') {
    await BackendlessUI.Functions.Custom['fn_9381ad6d0dd53b946a32f892b32fafee']('en', ___arguments.context.appData);
    ___arguments.context.pageData['language'] = 'en';
  } else {
    await BackendlessUI.Functions.Custom['fn_9381ad6d0dd53b946a32f892b32fafee']('fr', ___arguments.context.appData);
    ___arguments.context.pageData['language'] = 'fr';
  }

  },
  /* handler:onClick */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = 'EN';
  if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'en') {
    content2 = 'FR';
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pageErrorMessage')) == null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Entry/components/dff913d7db0ccf87e742b3fa02c43366/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
    content2 = 'Veuillez saisir votre nom complet et votre adresse courriel pour confirmer votre participation au concours pour ce lieu. Le(s) gagnant(s) sera(ont) contacté(s) par courriel.';
  } else {
    content2 = 'Please enter your full name and email address to confirm your contest entry for this location. Winner(s) will be contacted by email.';
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Entry/components/bc11a78e0c9dd047ba0571d6cdeb925a/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
    content2 = 'Nom complet';
  } else {
    content2 = 'Full Name';
  }

  return content2

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Entry/components/cad4159f53291e48c84c69cb77282497/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
    content2 = 'Adresse courriel';
  } else {
    content2 = 'Email Address';
  }

  return content2

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Entry/components/76fa9134a2dd6365e81aa3124ebeccfe/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
    content2 = 'Soumettre';
  } else {
    content2 = 'Submit';
  }

  return content2

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var userLocationCampaign, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}


  if ((getObjectProperty(___arguments.context.pageData, 'name')) == null || !(getObjectProperty(___arguments.context.pageData, 'name')).length) {
    if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
      ___arguments.context.pageData['formErrorMessage'] = 'Veuillez saisir votre nom complet.';
    } else {
      ___arguments.context.pageData['formErrorMessage'] = 'Please enter your full name.';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'email')) == null || !((new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$', '')).test((getObjectProperty(___arguments.context.pageData, 'email'))))) {
    if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
      ___arguments.context.pageData['formErrorMessage'] = 'Veuillez saisir votre nom complet.';
    } else {
      ___arguments.context.pageData['formErrorMessage'] = 'Veuillez saisir une adresse courriel valide.';
    }
  } else {
    ___arguments.context.pageData['formErrorMessage'] = null;
    user = (
        await (async function(userIdentity){
          var userColumns = await Backendless.UserService.describeUserClass()
          var identityColumn = userColumns.find(column => column.identity)
          var whereClause = `${identityColumn.name} = '${userIdentity}'`
          var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
          var users = await Backendless.Data.of(Backendless.User).find(query)

          return users[0]
       })((getObjectProperty(___arguments.context.pageData, 'email')))
    );
    if (user != null) {
      user['name'] = (getObjectProperty(___arguments.context.pageData, 'name'));
      await Backendless.UserService.update( new Backendless.User(user) );
      await BackendlessUI.Functions.Custom['fn_5188216b7b39207784cdfbd7e8aca7d9'](user, ___arguments.context.appData);
      ___arguments.context.pageData['user'] = user;
    } else {
      user = (await Backendless.UserService.register( new Backendless.User({ 'email': (getObjectProperty(___arguments.context.pageData, 'email')),'password': (JSON.stringify((mathRandomInt(100000, 999999)))) }) ));
      user['name'] = (getObjectProperty(___arguments.context.pageData, 'name'));
      await Backendless.UserService.update( new Backendless.User(user) );
      await BackendlessUI.Functions.Custom['fn_5188216b7b39207784cdfbd7e8aca7d9'](user, ___arguments.context.appData);
      ___arguments.context.pageData['user'] = user;
    }
    userLocationCampaign = (await Backendless.Data.of('UserLocationCampaign').find(Backendless.DataQueryBuilder.create().setWhereClause((['user.email=\'',(getObjectProperty(___arguments.context.pageData, 'user.email')),'\' and locationCampaign.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'locationCampaign.objectId')),'\''].join(''))).setPageSize(10)))[0];
    if (userLocationCampaign != null) {
      if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
        ___arguments.context.pageData['pageErrorMessage'] = 'Vous avez déjà créé une entrée pour ce lieu.';
      } else {
        ___arguments.context.pageData['pageErrorMessage'] = 'You have already created an entry for this location.';
      }
    } else {
      userLocationCampaign = (await Backendless.Data.of('UserLocationCampaign').save( ({ 'description': [(getObjectProperty(___arguments.context.pageData, 'user.email')),' - ',(getObjectProperty(___arguments.context.pageData, 'locationCampaign.location.name')),' - ',(getObjectProperty(___arguments.context.pageData, 'locationCampaign.campaign.name'))].join('') }) ));
      await Backendless.Data.of('UserLocationCampaign').setRelation(userLocationCampaign, 'user', [(getObjectProperty(___arguments.context.pageData, 'user.objectId'))]);
      await Backendless.Data.of('UserLocationCampaign').setRelation(userLocationCampaign, 'locationCampaign', [(getObjectProperty(___arguments.context.pageData, 'locationCampaign.objectId'))]);
      ___arguments.context.pageData['isEntryConfirmationTextDisplayed'] = true;
      ___arguments.context.pageData['entryConfirmationTextFr'] = (await BackendlessUI.Functions.Custom['fn_fdf7db16ad76081e4e2c4a0c05d61a83']('fr', (getObjectProperty(___arguments.context.pageData, 'user'))));
      ___arguments.context.pageData['entryConfirmationTextEn'] = (await BackendlessUI.Functions.Custom['fn_fdf7db16ad76081e4e2c4a0c05d61a83']('en', (getObjectProperty(___arguments.context.pageData, 'user'))));
      ___arguments.context.pageData['drawEntries'] = (await BackendlessUI.Functions.Custom['fn_955d5aaae1275eb30fc511115678a336']((getObjectProperty(___arguments.context.pageData, 'user')), (getObjectProperty(___arguments.context.pageData, 'locationCampaign'))));
      ___arguments.context.pageData['isDrawEntriesVisible'] = true;
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Entry/components/62f9135d93d434646076e76d50723f54/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'formErrorMessage')) != null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Entry/components/053aac24491f0169756e60e2ebd73269/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pageErrorMessage')) != null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Entry/components/9282056fc1ff93b3e48755b3c3a47f2a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'locationName')),' - ',(getObjectProperty(___arguments.context.dataModel, 'entryCreated'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Entry/components/a5f4b9712dbc701f3daba10b9e75d049/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
    content2 = 'Un courriel a été envoyé à votre adresse courriel avec un lien sur lequel vous pouvez cliquer pour le valider. Veuillez vérifier vos courriels indésirables si vous ne voyez pas ce courriel dans votre boîte de réception. Les participations au concours seront que prises en compte pour les adresses valides. L\'étape de validation de l\'adresse courriel ne doit être effectuée qu\'une seule fois.';
  } else {
    content2 = 'An email has been sent to your email address with a link you could click on to validate it. Please check your junk mail if you do not see this email in your inbox. Contest entries will considered for valid email addresses. The email validation step only needs to be done once.';
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Entry/components/8f4f7a241333583380e61c46cf3dbb39/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'language')) == 'fr') {
    content2 = (getObjectProperty(___arguments.context.pageData, 'entryConfirmationTextFr'));
  } else {
    content2 = (getObjectProperty(___arguments.context.pageData, 'entryConfirmationTextEn'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Entry/components/df62e97efac766ba15bc9c6b19efb697/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['user'] = null;
  await BackendlessUI.Functions.Custom['fn_5188216b7b39207784cdfbd7e8aca7d9'](null, ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Entry/components/4e310d95a615d2a2466ce717d89b3b7d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['user'] = null;
  await BackendlessUI.Functions.Custom['fn_5188216b7b39207784cdfbd7e8aca7d9'](null, ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/EntryList/components/74ee482eb066a788f7377ae54d4e637a/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  ['onRecordSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Location', ({ 'objectId': (getObjectProperty(___arguments.selectedRecord, 'objectId')) }));

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/EntryList/components/04a8a7be240f5d9914932ccac3aac396/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Location', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Location/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'objectId')) != null) {
    ___arguments.context.pageData['location'] = (await Backendless.Data.of('Location').findById((getObjectProperty(___arguments.context.pageData, 'objectId')), ));
    ___arguments.context.pageData['imageUrl'] = (getObjectProperty(___arguments.context.pageData, 'location.imageUrl'));
    ___arguments.context.pageData['name'] = (getObjectProperty(___arguments.context.pageData, 'location.name'));
    ___arguments.context.pageData['street'] = (getObjectProperty(___arguments.context.pageData, 'location.street'));
  }

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/Location/components/f2564662f86d72ed410270c351c39a71/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = 'Location';
  if ((getObjectProperty(___arguments.context.pageData, 'objectId')) == null) {
    content2 = 'New Location';
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Location/components/ad05b71ee18719b7d83d5e264fdfdadc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'location')) != null)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var locationCampaign;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'location')) != null) {
    locationCampaign = (await Backendless.Data.of('LocationCampaign').find(Backendless.DataQueryBuilder.create().setWhereClause((['location.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'location.objectId')),'\''].join(''))).setRelated(['location', 'campaign']).setPageSize(10)))[0];
    console.log(JSON.stringify(locationCampaign));
    if (locationCampaign != null) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('LocationQR', ({ 'objectId': (getObjectProperty(locationCampaign, 'objectId')) }));
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Location/components/68563a9deb032b2dde8d81c3c48b3c8b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var campaign, locationCampaign;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'location')) == null) {
    ___arguments.context.pageData['location'] = (await Backendless.Data.of('Location').save( ({ 'imageUrl': (getObjectProperty(___arguments.context.pageData, 'imageUrl')),'name': (getObjectProperty(___arguments.context.pageData, 'name')),'street': (getObjectProperty(___arguments.context.pageData, 'street')) }) ));
    campaign = (await Backendless.Data.of('Campaign').findFirst());
    locationCampaign = (await Backendless.Data.of('LocationCampaign').save( ({ 'description': (getObjectProperty(___arguments.context.pageData, 'name')) }) ));
    await Backendless.Data.of('LocationCampaign').setRelation(locationCampaign, 'location', [(getObjectProperty(___arguments.context.pageData, 'location'))]);
    await Backendless.Data.of('LocationCampaign').setRelation(locationCampaign, 'campaign', [campaign]);
  } else {
    (getObjectProperty(___arguments.context.pageData, 'location'))['imageUrl'] = (getObjectProperty(___arguments.context.pageData, 'imageUrl'));
    (getObjectProperty(___arguments.context.pageData, 'location'))['name'] = (getObjectProperty(___arguments.context.pageData, 'name'));
    (getObjectProperty(___arguments.context.pageData, 'location'))['street'] = (getObjectProperty(___arguments.context.pageData, 'street'));
    ___arguments.context.pageData['location'] = (await Backendless.Data.of('Location').save( (getObjectProperty(___arguments.context.pageData, 'location')) ));
  }
  (function (message) { alert(message) })('Save successful.');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Location/components/259cad5dcca5c57b68c7a5783bbe1d0f/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['imageUrl'] = ___arguments.uploadedFiles;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('259cad5dcca5c57b68c7a5783bbe1d0f');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/LocationQR/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['locationCampaign'] = (await Backendless.Data.of('LocationCampaign').findById((getObjectProperty(___arguments.context.pageData, 'objectId')), Backendless.DataQueryBuilder.create().setRelated(['location', 'campaign'])));
  ___arguments.context.pageData['QRCodeValue'] = (['https://spotfinity.com/?page=Entry&data=%7B%22code%22%3A%22',(getObjectProperty(___arguments.context.pageData, 'objectId')),'%22%7D'].join(''));

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/Locations/components/74ee482eb066a788f7377ae54d4e637a/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  ['onRecordSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Location', ({ 'objectId': (getObjectProperty(___arguments.selectedRecord, 'objectId')) }));

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/Locations/components/04a8a7be240f5d9914932ccac3aac396/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Location', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Utils/components/452390bfe4138b86a76e2cdf26e5268e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Entry', ({ 'code': '376A4877-010D-478C-B0C3-145C93571E83' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Utils/components/113382e6c47c709a386c50fd80a1388e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Entry', ({ 'code': '6EC0512D-FD06-459B-A691-05484E1AD32A' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Utils/components/03248bf72c08522d4abf95f73a3f28de/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.Messaging.sendEmailFromTemplate('UserEmailValidation', Backendless.EmailEnvelope.create({ addresses: ['marcchriqui@hotmail.com'], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), undefined, undefined);
  (function (message) { alert(message) })('Done!');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Utils/components/6f69a828d6062747485783b12a9672a7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      localStorage.removeItem('user');

  },
  /* handler:onClick */
  /* content */
}))

